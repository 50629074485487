export const routeFactory = {
  siteMap: {
    overview: (siteId: string, companyId: string) => `/companies/${companyId}/sites/${siteId}/map`,
    machine: (siteId: string, companyId: string, machineId: string) =>
      `/companies/${companyId}/sites/${siteId}/map/machine/${machineId}`,
  },
  process: {
    overview: (siteId: string, companyId: string) =>
      `/companies/${companyId}/sites/${siteId}/process`,
  },
  deviations: {
    overview: (siteId: string, companyId: string) =>
      `/companies/${companyId}/sites/${siteId}/deviations`,
    unassignedSiteLoadTickets: (siteId: string, companyId: string) =>
      `/companies/${companyId}/sites/${siteId}/deviations/unmapped-load-tickets`,
    materialMapping: (siteId: string, companyId: string) =>
      `/companies/${companyId}/sites/${siteId}/deviations/material-mapping`,
  },
  admin: {
    overview: (siteId: string, companyId: string) =>
      `/companies/${companyId}/sites/${siteId}/admin`,
  },
};
